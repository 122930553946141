<template>
  <div class="">
    <h1>Monitoring page</h1>
    <tl-feedbacks-admin app-feedback class="mt-12" />
  </div>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlFeedbacksAdmin from '@/components/gym/admin/comm/feedbacks/tl-feedbacks-admin'
import store from '@/store'

export default {
  mixins: [toolbarMixin],
  components: {
    tlFeedbacksAdmin,
  },
  data: () => ({
    tlToolbarTitle: 'Monitoring',
  }),
  beforeRouteEnter(to, from, next) {
    if (!store.state.user.admin) return next(false)
    next()
  },
}
</script>
